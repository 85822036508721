<template>
  <div>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <span class="kumbhBold" style="font-size: 18px"
          >ID Proofs (Docs shall be added only in PDF form.)</span
        >
      </v-flex>
      <v-flex xs12 pt-5>
        <v-layout wrap>
          <v-flex xs12 sm6 md4>
            <span class="kumbhSemibold">AdharCard</span>
          </v-flex>
          <v-flex xs12 sm6 md8 text-right>
            <v-layout wrap>
              <v-flex xs12>
                <v-chip
                  v-if="adharCard.name"
                  class="ma-2"
                  outlined
                  color="#005f32"
                  text-color="#929292"
                >
                  <v-layout wrap justify-center>
                    <v-flex xs12 text-left pa-2>
                      <span
                        >{{ adharCard.name.slice(0, 40) }}
                        <span v-if="adharCard.name.length > 40">
                          ...
                        </span></span
                      ></v-flex
                    >
                  </v-layout>
                </v-chip>
                <v-btn
                  color="#005f32"
                  dark
                  small
                  @click="$refs.adharCard.click()"
                  ><span
                    style="
                      font-size: 12px;
                      text-align: end;
                      letter-spacing: 0px;
                      opacity: 1;
                    "
                  >
                    UPLOAD
                  </span></v-btn
                >
                <input
                  v-show="false"
                  id="file"
                  ref="adharCard"
                  type="file"
                  accept="application/pdf"
                  @change="adharCardUpload($event)"
                />
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout wrap pt-5>
          <v-flex xs12 sm6 md4>
            <span class="kumbhSemibold">Pan Card</span>
          </v-flex>
          <v-flex xs12 sm6 md8 text-right>
            <v-layout wrap>
              <v-flex xs12>
                <v-chip
                  v-if="panCard.name"
                  class="ma-2"
                  outlined
                  color="#005f32"
                  text-color="#929292"
                >
                  <v-layout wrap justify-center>
                    <v-flex xs12 text-left pa-2>
                      <span
                        >{{ panCard.name.slice(0, 40) }}
                        <span v-if="panCard.name.length > 40"> ... </span></span
                      ></v-flex
                    >
                  </v-layout>
                </v-chip>
                <v-btn color="#005f32" dark small @click="$refs.panCard.click()"
                  ><span
                    style="
                      font-size: 12px;
                      text-align: end;
                      letter-spacing: 0px;
                      opacity: 1;
                    "
                  >
                    UPLOAD
                  </span></v-btn
                >
                <input
                  v-show="false"
                  id="file"
                  ref="panCard"
                  type="file"
                  accept="application/pdf"
                  @change="panCardUpload($event)"
                />
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout wrap pt-5>
          <v-flex xs12 sm6 md4>
            <span class="kumbhSemibold">Passport/Licence</span>
          </v-flex>
          <v-flex xs12 sm6 md8 text-right>
            <v-layout wrap>
              <v-flex xs12>
                <v-chip
                  v-if="paasportOrlicence.name"
                  class="ma-2"
                  outlined
                  color="#005f32"
                  text-color="#929292"
                >
                  <v-layout wrap justify-center>
                    <v-flex xs12 text-left pa-2>
                      <span
                        >{{ paasportOrlicence.name.slice(0, 40) }}
                        <span v-if="paasportOrlicence.name.length > 40">
                          ...
                        </span></span
                      ></v-flex
                    >
                  </v-layout>
                </v-chip>
                <v-btn color="#005f32" dark small @click="$refs.pl.click()"
                  ><span
                    style="
                      font-size: 12px;
                      text-align: end;
                      letter-spacing: 0px;
                      opacity: 1;
                    "
                  >
                    UPLOAD
                  </span></v-btn
                >
                <input
                  v-show="false"
                  id="file"
                  ref="pl"
                  type="file"
                  accept="application/pdf"
                  @change="paasportOrlicenceUpload($event)"
                />
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center py-5>
      <v-flex xs12 text-center>
        <v-btn color="#005f32" dark @click="add()"
          ><span>Save Details</span></v-btn
        >
      </v-flex>
    </v-layout>
  </div>
</template>
  <script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      showsnackbar: false,
      ServerError: false,
      msg: null,
      adharCard: "",
      panCard: "",
      paasportOrlicence: "",
      formData: new FormData(),
    };
  },

  methods: {
    adharCardUpload(event) {
      this.adharCard = event.target.files[0];
    },
    panCardUpload(event) {
      this.panCard = event.target.files[0];
    },
    paasportOrlicenceUpload(event) {
      this.paasportOrlicence = event.target.files[0];
    },
    add() {
      let formData = new FormData();
      formData.append("adharCard", this.adharCard);
      formData.append("panCard", this.panCard);
      formData.append("paasportOrlicence", this.paasportOrlicence);
      axios({
        method: "POST",
        url: "/bulletin/add",
        data: formData,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            formData = new FormData();
            this.adharCard = "";
            this.appLoading = false;
            this.$emit("stepper", {
              ref: "page1Tab",
              level: "tab-2",
            });
          } else {
            this.appLoading = false;
            this.msg = response.data.msg;
            this.showsnackbar = true;
            formData = new FormData();
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
  