<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12>
        <!-- <v-card> -->
        <v-card-title class="elevation-0">
          <v-spacer></v-spacer>
        </v-card-title>
        <v-layout wrap justify-center v-if="details">
          <v-flex xs12 lg10 py-4 align-self-center text-center>
            <span class="kumbhBold" style="font-size: 18px"
              >Document Details</span
            >
          </v-flex>
          <v-flex xs12 lg10>
            <v-layout wrap pa-4>
              <v-flex xs12 sm2 text-left align-self-center>
                <span class="kumbhSemibold">Title</span>
              </v-flex>
              <v-flex xs12 sm10 py-2 py-sm-0 text-left align-self-center>
                <span class="kumbhRegular">{{ details.title }}</span>
              </v-flex>
            </v-layout>

            <v-layout wrap pa-4>
              <v-flex xs12 sm2 text-left align-self-center>
                <span class="kumbhSemibold">Descripton</span>
              </v-flex>
              <v-flex xs12 sm10 py-2 py-sm-0 text-left align-self-center>
                <span class="kumbhRegular" v-html="details.description"></span>
              </v-flex>
            </v-layout>

            <!-- <v-layout wrap pa-4>
              <v-flex xs12 sm2 text-left align-self-center>
                <span class="kumbhSemibold">Files</span>
              </v-flex>
              <v-flex
                xs12
                sm10
                text-left
                align-self-center
                v-if="details.filename"
              >
                <v-layout wrap v-if="details.filename.length > 0" justify-start>
                  <v-flex
                    xs12
                    pa-2
                    text-left
                    align-self-center
                    v-for="(file, f) in details.filename"
                    :key="f"
                  >
                    <a target="_blank" :href="mediaURL + file">
                      <v-chip outlined color="#e27826">
                        <span style="color: #e27826">{{ file }}</span>
                        &nbsp;
                        <v-btn
                          dark
                          class="text-capitalize"
                          rounded
                          depressed
                          x-small
                          color="#e27826"
                        >
                          <v-icon small color="white">mdi-download</v-icon>
                        </v-btn>
                      </v-chip>
                    </a>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout> -->

            <!-- <v-layout wrap>
              <v-flex xs12 py-6 v-if="mydocs">
                <v-divider></v-divider>
              </v-flex>

              <v-flex xs12 py-3 align-self-center text-left v-if="mydocs">
                <span class="kumbhBold">My Documents</span>
              </v-flex>
              <v-flex xs12 v-if="mydocs" py-2>
                <v-card outlined>
                  <v-layout wrap v-if="mydocs.filename" justify-start>
                    <v-flex
                      xs12
                      pa-2
                      v-if="mydocs.filename.length > 0"
                      align-self-center
                      text-left
                    >
                      <v-layout wrap justify-start>
                        <v-flex
                          pa-2
                          xs12
                          sm3
                          align-self-center
                          text-left
                          v-for="(my, y) in mydocs.filename"
                          :key="y"
                        >
                          <a target="_blank" :href="mediaURL + my">
                            <v-chip outlined dark color="#e27826">
                              {{ my }}
                              &nbsp;
                              <v-btn
                                color="#e27826"
                                dark
                                rounded
                                depressed
                                x-small
                              >
                                view
                              </v-btn>
                            </v-chip>
                          </a>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
              <v-flex xs12 py-8>
                <v-divider></v-divider>
              </v-flex>
              <v-flex xs12 align-self-center text-left>
                <span class="kumbhBold">Upload Documents</span>
              </v-flex>
              <v-flex xs12 py-3>
                <v-card outlined>
                  <v-layout wrap justify-center>
                    <v-flex xs12 pt-6>
                      <v-icon
                        x-large
                        color="#408BFF"
                        @click="$refs.files.click()"
                        >mdi-cloud-upload</v-icon
                      >
                    </v-flex>
                    <v-flex xs12 pb-5>
                      <span class="con4">Click here to upload Document</span>
                      &nbsp;
                      <input
                        v-show="false"
                        id="file1"
                        ref="files"
                        type="file"
                        multiple
                        @change="browseCover"
                      />
                    </v-flex>
                    <v-flex xs12 py-3 text-left align-self-center>
                      <v-chip-group v-for="(item, i) in documentFile1" :key="i">
                        <v-layout wrap>
                          <v-flex pa-2>
                            <v-chip
                              @click="documentFile1.splice(i, 1)"
                              dark
                              color="green"
                            >
                              {{ item.name }} &nbsp;
                              <v-icon>mdi-close</v-icon>
                            </v-chip>
                          </v-flex>
                        </v-layout>
                      </v-chip-group>
                      <v-chip-group
                        v-for="(item, i) in editingitem.filename"
                        :key="i"
                      >
                        <v-chip
                          @click="
                            deletePDF(item, editingitem._id),
                              editingitem.filename.splice(i, 1)
                          "
                          close
                          dark
                          color="green"
                          style="margin-right: 6px; margin-bottom: 10px"
                        >
                          {{ item }}
                        </v-chip>
                      </v-chip-group>
                    </v-flex>
                    <v-flex xs6 sm3 md2 align-self-center text-center py-4>
                      <v-btn
                        @click="validateUpload()"
                        rounded
                        block
                        color="#e27826"
                        dark
                      >
                        Upload
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout> -->
          </v-flex>
        </v-layout>
        <v-layout wrap>
          <v-flex xs12>
            <v-tabs
              v-model="tabValue"
              background-color="#005f32"
              grow
              dark
              center-active
              centered
              :show-arrows="true"
              active-class="activ"
              class="nonactiv"
              style="font-family: kumbhSemibold"
            >
              <v-tab href="#tab-1"
                > <span style="color: #fff">Personal Information</span></v-tab
              >
              <v-tab  dark href="#tab-2"> <span style="color: #fff">Id Proofs</span></v-tab>
              <v-tab dark href="#tab-3"><span style="color: #fff">Education Details</span></v-tab>
              <v-tab href="#tab-4"><span style="color: #fff">Past Experience</span></v-tab>
              <v-tab href="#tab-5"><span style="color: #fff">Insurance & Financial Details</span></v-tab>
              <v-tab href="#tab-6"><span style="color: #fff">Legal Details</span></v-tab>
              <v-tab href="#tab-7"><span style="color: #fff">Contact Details</span></v-tab>
              <v-tab-item value="tab-1">
                <v-card flat>
                  <v-layout wrap justify-start py-5>
                    <v-flex xs12 pl-3 text-left>
                      <PersonalInfo @stepper="winStepper" />
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-tab-item>
              <v-tab-item value="tab-2">
                <v-card flat>
                  <v-layout wrap justify-start py-5>
                    <v-flex xs12 pl-3 text-left>
                      <IdProofs @stepper="winStepper" />
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-flex>
        </v-layout>
        <!-- </v-card> -->
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
// import ImageComp from  "@/components/Common/singleImage";
import axios from "axios";
import PersonalInfo from "./personalInfo";
import IdProofs from "./idProofs";
export default {
  components: {
    PersonalInfo,
    IdProofs
  },
  data() {
    return {
      tabValue: "tab-1",
      appLoading: false,
      documentList: [],
      title: null,
      documentFile1: [],
      documentFile: null,
      documentID: null,
      docsdata: new FormData(),
      g: null,
      //end
      showsnackbar: false,
      ServerError: false,
      msg: null,
      pages: 0,
      dialog: false,
      editdialog: false,
      editingitem: [],
      name: "",
      content: "",
      dialogDelete: false,
      search: "",
      itemname: "",
      addcat: false,
      rules: {
        required: (value) => !!value || "Required.",
      },

      formDataCover: new FormData(),
      flagg: true,
      details: null,
      mydocs: null,
    };
  },
  mounted() {
    this.getData();
    this.getMyUploads();
  },
  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    winStepper(item) {
      window.scrollTo(0, 0);
      if (item.ref == "page1Tab") {
        if (
          item.level
        ) {
          this.tabValue = item.level;
        }
      }
    },
    addDocument() {
      if (this.documentFile1.length > 0) {
        this.appLoading = true;
        axios({
          url: "/employee/upload/document",
          method: "post",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          data: {
            id: localStorage.getItem("uid"),
            fid: this.documentFile1,
          },
        })
          .then((response) => {
            this.appLoading = false;
            this.documentID = response.data.data._id;
            console.log();
            if (this.documentID != null) {
              this.docsupload();
            } else {
              alert("document updated");
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      } else {
        this.showsnackbar = true;
        this.msg = "Please Select Document ";
      }
    },

    validateUpload() {
      if (this.documentFile1.length > 0) {
        this.docsupload();
      } else {
        this.msg = "Please Select Document ";
        this.showsnackbar = true;
      }
    },
    docsupload() {
      for (var i = 0; i < this.documentFile1.length; i++) {
        this.docsdata.append("document", this.documentFile1[i]);
      }
      this.docsdata.append("id", this.$route.query.id);
      this.docsdata.append("empid", localStorage.getItem("userid"));

      this.appLoading = true;
      axios({
        url: "/employee/upload/documentonboard",
        method: "post",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: this.docsdata,
      })
        .then((response) => {
          this.appLoading = false;
          this.dialog = false;
          this.documentFile1 = [];
          this.docsdata = new FormData();

          this.showsnackbar = true;
          this.getData();
          this.msg = response.data.msg;

          this.getMyUploads();
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    dialogclose() {
      this.name = null;
      this.dialog = false;
    },

    browseCover(event) {
      var got = event.target.files;
      if (got.length < 5) {
        for (let i = 0; i < got.length; i++) {
          this.documentFile1.push(got[i]);
        }
        console.log("file isss", this.documentFile1);
      } else {
        this.msg = "Maximum 3 can be uploaded";
        this.showSnackBar = true;
      }
    },

    browseCover1(event) {
      var got = event.target.files[0];
      console.log("result is", got);
      this.documentFile = got;

      //   if (got.type == "application/pdf") {
      //     this.coverFile = got;
      //   } else {
      //     var img;
      //     img = new Image();
      //     img.src = window.URL.createObjectURL(event.target.files[0]);
      //     var ty = event.target.files[0];
      //     console.log(ty);
      //     if (ty.size > 3145728) {
      //       document.getElementById("file1").value = [];
      //       this.msg = "File size Should be less than 3MB";
      //       this.showSnackBar = true;
      //       return;
      //     } else {
      //       this.coverFile = got;
      //     }
      //   }
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/employee/download/documentsearch",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.$route.query.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.details = response.data.data;
          } else {
            this.showsnackbar = true;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err());
        });
    },
    getMyUploads() {
      this.appLoading = true;
      axios({
        url: "/employee/view/myuploads",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.$route.query.id,
          empid: localStorage.getItem("userid"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.mydocs = response.data.data;
          } else {
            this.showsnackbar = false;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err());
        });
    },
    deleteItem(val) {
      this.dialogDelete = false;
      this.appLoading = true;
      axios({
        url: "/admin/remove/document",
        method: "post",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: val._id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.showsnackbar = true;
            this.msg = response.data.msg;
            this.getData();
            this.get;
          } else {
            this.showsnackbar = true;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err());
        });
    },
    editcat(items) {
      this.editdialog = true;
      this.editingitem = items;
    },
    deletePDF(name, id) {
      this.appLoading = true;
      axios({
        url: "/admin/remove/uploaddocument",
        method: "post",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          document: name,
          id: id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.dialog = false;

          this.showsnackbar = true;
          this.getData();
          this.msg = response.data.msg;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    edit(id) {
      this.appLoading = true;
      axios({
        url: "/admin/add/edit/document",
        method: "post",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: id,
          title: this.editingitem.title,
          description: this.editingitem.content,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.documentID = response.data.data._id;
          this.editdialog = false;
          if (this.documentID != null) {
            this.docsupload();
          } else {
            alert("document updated");
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style>
.activ {
  color: black !important;
  font-family: robotoRegular !important;
}
.nonactiv {
  color: #acacac !important;
  font-family: robotoRegular !important;
}
</style>
